import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/app/assets/svg/emoji.svg");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/app/assets/svg/github.svg");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/app/assets/svg/home.svg");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/app/assets/svg/page.svg");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/app/components/dark-mode-toggle.tsx");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/app/globals.css");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/app/libs/recoil/recoilProvider.tsx");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/app/libs/theme/theme-provider.tsx");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/Users/bearjb/Repositories/bearjb/node_modules/next/font/local/target.css?{\"path\":\"app/assets/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./PretendardVariable.woff2\"}],\"variableName\":\"pretendard\"}")